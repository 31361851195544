import React from "react";
import "../../styles/main.css";
import { graphql } from "gatsby";
import ServiceMap from "../../components/service-map";

export const query = graphql`
  query ServiceFinderIframePageQuery {
    page: allSanityPageServiceFinder {
      nodes {
        searchTitle
        searchSubtitle
        searchEmpty
      }
    },
    locations: allSanityServicelocation {
      nodes {
            _id
            id
            title
            surname
            address
            kind
            phone
            kind
            email
            location {
              lat
              lng
            }
      }
    }
  }
`;

const MapIframe = ({ data }) => {
  const page = data.page.nodes[0];
  return (
    <div className="iframe-wrapper">
      <ServiceMap
        locs={data.locations.nodes}
        searchTitle={page.searchTitle}
        searchSubtitle={page.searchSubtitle}
        searchEmpty={page.searchEmpty}
      />
    </div>
  );
};

export default MapIframe;
